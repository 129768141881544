import React from 'react'
import Layout from '../components/layout'

import {Deutsch, English, LinkHOME, LinkDE, LinkEN, CLink, Page, SiteTitle} from '../components/linksetc.js'




const ProjectPage = ({ location }) => (
          <Layout>

          <SiteTitle string="Work" />
        <meta name="description" content="Arbeiten als Texter und Konzepter, auf Deutsch und Englisch." />



                <Page location={location}>

                    <English Language={location.search}>


                          <div className="projects_list">

                          <CLink to="inventify" location={location}>inventify (wireframes / SEO / website copy / English / German)</CLink> <p/>
                          <CLink to="rready" location={location}>rready (website copy / English / German / pillar pages)</CLink> <p/>
                          <CLink to="bitbee" location={location}>BITBEE (SEO / website copy)</CLink> <p/>


                          <CLink to="albigna" location={location}>Solar Albigna (website copy)</CLink> <p/>
                          <CLink to="camel" location={location}>Camel Shortstory (text / plotting / concept)</CLink> <p/>

                          <CLink to="bluelion" location={location}>Bluelion (verbal identity / website copy)</CLink> <p/>
                          
                          <CLink to="telekom" location={location}>Get Charge (naming / branding / copy)</CLink> <p/>

                          <CLink to="zetrafilm" location={location}>Zetra image film (concept / copy)</CLink> <p/>

                          <CLink to="distylerie" location={location}>distylerie agency website (copy / SEO)</CLink> <p/>

                            <CLink to="zetra" location={location}>Zetra weekender blog (concept/ content)</CLink> <p/>
                              <CLink to="GNB" location={location}>ewz energy island (concept)</CLink> <p/>
                              <CLink to="bionic" location={location}>Bionic (SEO/ copywriting)</CLink> <p/>
                              <CLink to="devilstamp" location={location}>Devil stamp (Comic text)</CLink> <p/>
                              <CLink to="velocomic" location={location}>Bicycle revolution (Comic text)</CLink> <p/>
                              <CLink to="westlink" location={location}>westlink.forum (copywriting)</CLink> <p/>

                              <CLink to="powernewz" location={location}>powernewz (content)</CLink> <p/>
                              <CLink to="reproad" location={location}>REPROAD (copywriting / verbal identity)</CLink> <p/>
                              <CLink to="kilokilo" location={location}>KiloKilo (copywriting/verbal identity)</CLink> <p/>

                              <CLink to="solar" location={location}>ewz solar (Advertising Concept)</CLink> <p/>



                              </div>

                              You can also read everything <LinkDE location={location}>in german</LinkDE> or <LinkHOME location={location}>start over</LinkHOME>.


                    </English>


                    <Deutsch Language={location.search}>
                          <div className="projects_list">


                          <CLink to="inventify" location={location}>inventify (wireframes / SEO / website copy / Englisch / Deutsch)</CLink> <p/>
                          <CLink to="rready" location={location}>rready (website copy / Englisch / Deutsch / pillar pages)</CLink> <p/>
                          <CLink to="bitbee" location={location}>BITBEE (SEO / website copy)</CLink> <p/>



                          <CLink to="albigna" location={location}>Solar Albigna (Text Web)</CLink> <p/>
                          <CLink to="camel" location={location}>Camel Kurzgeschichte (Text / Konzeption)</CLink> <p/>

                          <CLink to="bluelion" location={location}>Bluelion (Verbale Identität / Text)</CLink> <p/>
                          
                          <CLink to="telekom" location={location}>Get Charge (Naming / Branding / Text)</CLink> <p/>

                          <CLink to="zetrafilm" location={location}>Zetra Image Film (Konzept / Text)</CLink> <p/>

                          <CLink to="distylerie" location={location}>distylerie agency website (Text / SEO)</CLink> <p/>


                              <CLink to="zetra" location={location}>Zetra Weekender (Redaktion / Content)</CLink> <p/>
                              <CLink to="GNB" location={location}>ewz Energie-Schwebeinsel (Konzept / UX)</CLink> <p/>

                              <CLink to="powernewz" location={location}>powernewz (Redaktion / Content)</CLink> <p/>

                              <CLink to="bionic" location={location}>Bionic (SEO/ Copywriting)</CLink> <p/>

                              <CLink to="devilstamp" location={location}>Briefmarke des Teufels (Comic)</CLink> <p/>

                              <CLink to="velocomic" location={location}>Velorevolution (Comic)</CLink> <p/>
                              <CLink to="westlink" location={location}>westlink.forum (Verbale Identität)</CLink> <p/>

                              <CLink to="reproad" location={location}>REPROAD (Text / Verbale Identität)</CLink> <p/>
                              <CLink to="kilokilo" location={location}>KiloKilo (Verbale Identität/ Text)</CLink> <p/>
                              <CLink to="solar" location={location}>ewz solar (Werbung / Konzeption)</CLink> <p/>

                              </div>

                              Du kannst auch alles <LinkEN location={location}>auf Englisch lesen</LinkEN> oder <LinkHOME location={location}>zurück zum Anfang</LinkHOME>.

                    </Deutsch>






                </Page>
          </Layout>

)

export default ProjectPage
